<template>
  <div
    :class="type"
    style="
      width: 259px;
      height: 108px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
    "
  >
    <img
      :src="require(`@/assets/screen/bj/icon_${type}.png`)"
      alt="icon_untreated"
    />
    <div style="text-align: center; margin-left: 8px">
      <div
        :style="`font-size: 32px;
          font-family: DIN;
          font-weight: bold;
          color: ${typeMap[type].color}`"
      >
        {{ value }}
      </div>
      <div style="font-size: 16px; font-weight: 400; color: #ffffff">
        {{ typeMap[type].lable }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //组件注册
  components: {},
  //组件传值
  props: {
    type: {
      type: String,
      default: 'processed',
    },
    value: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      typeMap: {
        processed: {
          lable: '已处理预警',
          color: '#25E3FF',
        },
        untreated: {
          lable: '未处理预警',
          color: '#fe1329',
        },
      },
    };
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {},
  //DOM访问
  mounted() {},
  //保存方法
  methods: {},
};
</script>

<style lang="scss" scoped>
.processed {
  right: 0;
  top: 60px;
  flex-direction: row-reverse;
  background: url('../../../../assets/screen/earlyWarning/rectangle_blue.png');
}
.untreated {
  left: 0;
  bottom: 140px;
  padding-right: 12px;
  background: url('../../../../assets/screen/earlyWarning/rectangle_red.png');
}
</style>
